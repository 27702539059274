import { v4 as uuidv4 } from "uuid";
import {
  IObjectMap,
  ILogger,
  IScenarioEventData,
  IScenarioLogger,
  ITelemetryDetails,
  Outcome,
  LoggerLevels,
  Gesture,
  ScenarioType,
  ScenarioName,
  IDeviceInfo,
  ITelemetryDataBag,
  ITelemetryData,
} from "./interface";
import { ScenarioLogger, ScenarioStep } from "./ScenarioLogger";
import { IDiagnosticsService } from "../diagnostics/interface";
import { DiagnosticsService } from "../diagnostics/DiagnosticsService";
import { IShortcutService } from "../shortcut-service/interface";
import {
  ApplicationInsights,
  IExtendedConfiguration,
  IExtendedTelemetryItem,
} from "@microsoft/1ds-analytics-web-js";
import { deploymentConfig } from "../../configs/deploymentConfigs";
import { loggerConfig } from "../../configs/loggerConfigs";
import { Store } from "redux";
import { RootState } from "../../core/store/store";
import { SessionStorage } from "../sessionStorage/SessionStorage";
import { isDevelopment } from "../../utilities/common/utils";

export enum EventTypes {
  Trace = "trace",
  Performance = "performance",
  Scenario = "scenario",
  UserBi = "userbi",
  NonUiEvent = "nonUiEvent",
}

enum UserBiBaseTypes {
  PanelView = "panelview",
  PanelAction = "panelaction",
}

enum UserBiTelemetryColumns {
  SourceComponent = "SourceComponent",
  SourceElement = "SourceElement",
  Scenario = "Action.Scenario",
  ScenarioType = "Action.ScenarioType",
  Gesture = "Action.Gesture",
  ActionOutcome = "Action.Outcome",
}

/**
 * ScenarioLogger scenarios.
 */
export enum Scenario {
  AccessTokenFetch = "AccessTokenFetch",
  HandleSocialMsalRedirectPromise = "HandleSocialMsalRedirectPromise",
  HandleWorkMsalRedirectPromise = "HandleWorkMsalRedirectPromise",
  CancelRegistration = "CancelRegistration",
  CancelRegistrationRequest = "CancelRegistrationRequest",
  EventRequest = "EventRequest",
  SponsorsRequest = "SponsorsRequest",
  SponsorsOrderRequest = "SponsorsOrderRequest",
  EventRegistration = "EventRegistration",
  EventRegistrationRequest = "EventRegistrationRequest",
  LoadEcsConfig = "LoadEcsConfig",
  FetchEcsConfig = "FetchEcsConfig",
  ScheduledFetchEcsConfig = "ScheduledFetchEcsConfig",
  Login = "Login",
  Logout = "Logout",
  LogoutAfterSilentTokenFetchFailure = "LogoutAfterSilentTokenFetchFailure",
  RegistrationDetails = "RegistrationDetails",
  RegistrationDetailsRequest = "RegistrationDetailsRequest",
  RegistrationStatusFromRegistrationID = "RegistrationStatusFromRegistrationID",
  SignedInUserRegistrationStatus = "SignedInUserRegistrationStatus",
  TenantRegionRequest = "TenantRegionRequest",
  VisitorSkypeTokenFetch = "VisitorSkypeTokenFetch",
  BadgerTokenFetch = "BadgerTokenFetch",
  AuthSkypeTokenFetch = "AuthSkypeTokenFetch",
  MeRequest = "MeRequest",
  MePostRequest = "MePostRequest",
  MeUpdateRequest = "MeUpdateRequest",
  MeDeleteRequest = "MeDeleteRequest",
  WebVitalsMetric = "WebVitalsMetric",
  PageViewCountRequest = "PageViewCountRequest",
  RecordingPlaybackInfoRequest = "RecordingPlaybackInfoRequest",
  AmpSetup = "AmpSetup",
  AmpPlayback = "AmpPlayback",
  OnePlayerSetup = "OnePlayerSetup",
  OnePlayerPlayback = "OnePlayerPlayback",
  ErrorPages = "ErrorPages",
}

export enum SubScenario {
  EventDetailsRetrieved = "EventDetailsRetrieved",
}

export enum ScenarioStepStatus {
  Success = "Success",
  Fail = "Fail",
}

export enum UserBIScenario {
  CancelRegistration = "CancelRegistration",
  CancelRegistrationSubmission = "CancelRegistrationSubmission",
  ClickShareButton = "ClickShareButton",
  CopyEventLink = "CopyEventLink",
  JoinEvent = "JoinEvent",
  Logout = "Logout",
  ManageAccount = "ManageAccount",
  ProfileAvatar = "ProfileAvatar",
  ExportAccount = "ExportAccount",
  DeleteAccount = "DeleteAccount",
  RegisterEvent = "RegisterEvent",
  RegisterRecurringWebinarOccurance = "RegisterRecurringWebinarOccurance",
  ShareToFacebook = "ShareToFacebook",
  ShareToLinkedIn = "ShareToLinkedIn",
  ShareToTwitter = "ShareToTwitter",
  SocialLogin = "SocialLogin",
  SubmitRegistration = "SubmitRegistration",
  Unknown = "Unknown",
  ViewAgenda = "ViewAgenda",
  ViewInformation = "ViewInformation",
  ViewSessions = "ViewSessions",
  ViewSpeakers = "ViewSpeakers",
  ViewSponsors = "ViewSponsors",
  WorkLogin = "WorkLogin",
  OrgnizerPrivacyPolicyLink = "OrgnizerPrivacyPolicyLink",
  PrivacyPolicyLink = "PrivacyPolicyLink",
  SeeMoreLink = "SeeMoreLink",
  EventAddToCalenderButton = "EventAddToCalenderButton",
  AddToGoogleCalendar = "AddToGoogleCalendar",
  AddToAppleCalendar = "AddToAppleCalendar",
  AddToOutlookCalendar = "AddToOutlookCalendar",
  AddToMicrosoft365Calendar = "AddToMicrosoft365Calendar",
  AddToOutlookLiveCalendar = "AddToOutlookLiveCalendar",
  LearnMoreWebinarButton = "LearnMoreWebinarButton",
  CreateWebinarButton = "CreateWebinarButton",
}

export enum LoggerSessionStoragePropertyKey {
  SessionId = "logger.SessionId",
}

export class Logger implements ILogger {
  private static _instance: Logger | null;

  public getCommonPropertiesToLog?: () => ITelemetryData;
  public sessionId = "00000000-0000-0000-0000-000000000000";
  public teamsSessionId = "00000000-0000-0000-0000-000000000000";
  public deployment = "";
  public version = "";
  public userAgent = "";

  private readonly oneDSAppId = "virtualEventsPortal";

  private activeScenarios: IObjectMap<IScenarioLogger>;

  private diagnosticsService: IDiagnosticsService;

  private analytics?: ApplicationInsights;

  private store?: Store<RootState>;

  private constructor(oneDsSdkTenantKey?: string, sessionId?: string) {
    /**
     * Session ID persists for a browser tab, this will lead to persistant session ID even when user refreshs the page.
     * But we need this for flows that redirect away and back to this app (Eg. Redirect login).
     */
    this.sessionId =
      sessionId ||
      SessionStorage.get<string>(LoggerSessionStoragePropertyKey.SessionId) ||
      uuidv4();
    SessionStorage.put(
      LoggerSessionStoragePropertyKey.SessionId,
      this.sessionId
    );

    this.deployment = deploymentConfig.deployment;
    this.version = process.env.__VERSION__ as string;
    this.userAgent = navigator.userAgent;

    this.activeScenarios = {};

    this.diagnosticsService = new DiagnosticsService(
      this,
      loggerConfig.logDiagnosticsToConsole
    );

    if (!loggerConfig.disableTelemetry && oneDsSdkTenantKey) {
      this.analytics = new ApplicationInsights();
      const config: IExtendedConfiguration = {
        instrumentationKey: oneDsSdkTenantKey,
        endpointUrl: loggerConfig.oneDsEndpointOverride,
        propertyConfiguration: {
          populateBrowserInfo: true,
          populateOperatingSystemInfo: true,
          dropIdentifiers: true,
        },
        webAnalyticsConfiguration: {
          autoCapture: {
            // If auto capture page view is on, it will be triggered as soon as init is called, before app ID is set.
            // Turn it off, and trigger it manually.
            pageView: false,
          },
        },
        disableCookiesUsage: true,
      };
      this.analytics.initialize(config, []);
      this.analytics.getPropertyManager().getPropertiesContext().app.id =
        this.oneDSAppId;
      this.analytics.trackPageView({});
    }
  }

  public static getInstance(): Logger {
    return (
      this._instance ||
      (this._instance = new this(loggerConfig.oneDsSdkTenantKey))
    );
  }

  public getDeviceInfo(): IDeviceInfo {
    return {
      browserName: this.analytics?.getPropertyManager().getPropertiesContext()
        .web.browser,
      browserVer: this.analytics?.getPropertyManager().getPropertiesContext()
        .web.browserVer,
      osName: this.analytics?.getPropertyManager().getPropertiesContext().os
        .name,
      osVer: this.analytics?.getPropertyManager().getPropertiesContext().os.ver,
    };
  }

  public initShortcut(shortcutService: IShortcutService): void {
    this.diagnosticsService.initShortcut(shortcutService);
  }

  public setStore(store: Store): void {
    this.store = store;
    this.diagnosticsService.setStore(store);
  }

  public download(): void {
    this.diagnosticsService.download();
  }

  public logException(message: string, options: ITelemetryDetails): void {
    if (isDevelopment()) {
      console.log(
        message,
        JSON.stringify(options, ["name", "message", "stack"])
      );
    }
    this.logTrace(LoggerLevels.error, message, options);
  }

  public logPerformance(
    source: string,
    result: boolean,
    duration: number,
    correlationVector: string = "",
    options?: ITelemetryDetails,
    scenario?: IScenarioLogger
  ): void {
    const event: IExtendedTelemetryItem = {
      name: EventTypes.Performance,
      data: {},
    };

    this.addCommonProperties(event);

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const data = event.data!;
    data.Result = result;
    data.Duration = duration;
    data.Source = source;
    data.CorrelationVector = correlationVector;
    data.Message = JSON.stringify(options);
    if (scenario) {
      data.ScenarioId = scenario?.id;
    }

    this.analytics?.trackEvent(event);
  }

  public logUiTelemetry(
    scenarioType: ScenarioType,
    scenario: string,
    gesture: Gesture,
    actionOutcome: Outcome,
    sourceElement: string,
    dataBag?: ITelemetryDataBag
  ): void {
    const event: IExtendedTelemetryItem = {
      name: EventTypes.UserBi,
      data: {},
      baseType: UserBiBaseTypes.PanelView,
    };

    this.addCommonProperties(event);

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const data = event.data!;
    data[UserBiTelemetryColumns.ScenarioType] = scenarioType;
    data[UserBiTelemetryColumns.SourceElement] = sourceElement;
    data[UserBiTelemetryColumns.Scenario] = scenario;
    data[UserBiTelemetryColumns.Gesture] = gesture;
    data[UserBiTelemetryColumns.ActionOutcome] = actionOutcome;

    if (dataBag) {
      this.addDetailsToEventData(dataBag, event);
    }

    this.analytics?.trackEvent(event);
  }

  public logAction(
    scenarioType: ScenarioType | ScenarioName,
    scenario: string,
    actionOutcome: Outcome = "none",
    dataBag?: ITelemetryDataBag
  ): void {
    const event: IExtendedTelemetryItem = {
      name: EventTypes.UserBi,
      data: {},
      baseType: UserBiBaseTypes.PanelAction,
    };

    this.addCommonProperties(event);

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const data = event.data!;
    data[UserBiTelemetryColumns.Gesture] = "";
    data[UserBiTelemetryColumns.ScenarioType] = scenarioType;
    data[UserBiTelemetryColumns.Scenario] = scenario;
    data[UserBiTelemetryColumns.ActionOutcome] = actionOutcome;

    if (dataBag) {
      this.addDetailsToEventData(dataBag, event);
    }

    this.analytics?.trackEvent(event);
  }

  public logNonUiTelemetry(
    nonUiComponent: string,
    outcome: Outcome,
    details?: ITelemetryDataBag
  ): void {
    const event: IExtendedTelemetryItem = {
      name: EventTypes.NonUiEvent,
      data: {},
    };

    this.addCommonProperties(event);

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const data = event.data!;
    data.SourceComponent = nonUiComponent;
    data.Event = outcome;

    if (details) {
      this.addDetailsToEventData(details, event);
    }

    this.analytics?.trackEvent(event);
  }

  public createScenario(
    name: ScenarioName,
    eventData?: Partial<IScenarioEventData>
  ): IScenarioLogger | null {
    if (!name) {
      return null;
    }

    if (this.activeScenarios[name] != null) {
      this.logTrace(
        LoggerLevels.warn,
        `trying to create a scenario that already exists. Scenario: ${name}`
      );
      return this.activeScenarios[name];
    }

    const event: IExtendedTelemetryItem = {
      name: EventTypes.Performance,
      data: {},
    };

    this.addCommonProperties(event);

    const newScenario = new ScenarioLogger(this, name, eventData);
    this.activeScenarios[name] = newScenario;
    return newScenario;
  }

  public findScenario(name: ScenarioName): IScenarioLogger | null {
    return this.activeScenarios[name] ? this.activeScenarios[name] : null;
  }

  public insertScenario(scenario: IScenarioLogger | null): boolean {
    if (scenario === null) {
      return false;
    }

    if (this.activeScenarios[scenario.name]) {
      this.logTrace(
        LoggerLevels.warn,
        `trying to create a scenario that already exists. Scenario: ${scenario.name}`
      );
      return false;
    }

    this.activeScenarios[scenario.name] = scenario;
    return true;
  }

  public stopScenario(
    name: ScenarioName,
    eventData?: Partial<IScenarioEventData>
  ): void {
    const scenario = this.findScenario(name);
    if (scenario && !scenario.isScenarioStopped()) {
      scenario.stop(eventData);
    }
  }

  public failScenario(
    name: ScenarioName,
    eventData?: Partial<IScenarioEventData>
  ): void {
    const scenario = this.findScenario(name);
    if (scenario && !scenario.isScenarioStopped()) {
      scenario.fail(eventData);
    }
  }

  public logScenario(
    name: ScenarioName,
    eventData: IScenarioEventData,
    completeScenario: boolean
  ): void {
    const event: IExtendedTelemetryItem = {
      name: EventTypes.Scenario,
      data: {},
    };

    this.addCommonProperties(event);

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const data = event.data!;
    data.ScenarioName = name;

    const values = Object.values(eventData);
    Object.keys(eventData).forEach((key: string, index: number) => {
      if (key === "data") {
        const eventDataData = values[index];
        Object.keys(eventDataData).forEach((dataKey: string) => {
          data[dataKey] = eventDataData[dataKey];
        });
      } else {
        data[key] = values[index];
      }
    });

    this.analytics?.trackEvent(event);

    this.pushScenarioDiagnosticsEvent(name, eventData);

    if (completeScenario) {
      delete this.activeScenarios[name];
    }
  }

  private pushScenarioDiagnosticsEvent(
    name: ScenarioName,
    eventData: IScenarioEventData
  ): void {
    const step = eventData.step;
    const sequence = eventData.sequence;
    let message = `[Scenario] ${name}`;
    const stepLog =
      step === ScenarioStep.Begin
        ? step
        : step === ScenarioStep.End
        ? `[step](${sequence})${step} (${eventData.stepDelta}ms/${eventData.delta}ms)`
        : `[step](${sequence})${step} (${eventData.stepDelta}ms)`;
    message += ` ${stepLog}`;
    if (eventData.message) {
      message += ` Message:"${eventData.message}"`;
    }
    if (eventData.data) {
      message += ` Data:${JSON.stringify(eventData.data)}`;
    }
    this.diagnosticsService.pushDiagnosticsEvent(LoggerLevels.info, message);
  }

  public logTrace(
    level: LoggerLevels,
    message: string,
    options?: ITelemetryDetails
  ): void {
    const event: IExtendedTelemetryItem = {
      name: EventTypes.Trace,
      data: {},
    };

    this.addCommonProperties(event);

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const data = event.data!;
    data.LogLevel = level;
    data.Message = message;

    if (options) {
      if (options.error) {
        if (options.error.stack) {
          data.CallStack = options.error.stack;
        }

        if (options.error.name) {
          data.ExceptionType = options.error.name;
        }

        if (options.error.message) {
          data.ExceptionMessge = options.error.message;
        }
      }

      if (options.details) {
        data.Details = options.details;
      }
    }

    this.analytics?.trackEvent(event);
    this.diagnosticsService.pushDiagnosticsEvent(level, message);
  }

  public getLogs(): string {
    return this.diagnosticsService.getLogs();
  }

  private addCommonProperties(event: IExtendedTelemetryItem): void {
    if (!event.data) {
      event.data = {};
    }
    const data = event.data;

    data.EventType = this.store?.getState().event.eventObject?.type || "";
    data.EventID = this.store?.getState().event.eventID || "";
    data.EventTenantID =
      this.store?.getState().event.eventObject?.organization?.tenantId || "";
    data.UserTenantID =
      this.store?.getState().user?.user?.identity?.tenantId || "";
    data.EventSessionID = this.sessionId;
    data.Deployment = this.deployment;
    data.Version = this.version;
    if (this.getCommonPropertiesToLog) {
      const commonProperties = this.getCommonPropertiesToLog();
      Object.keys(commonProperties).forEach((key: string) => {
        data[key] = commonProperties[key];
      });
    }
  }

  private addDetailsToEventData(
    details: ITelemetryDataBag,
    event: IExtendedTelemetryItem
  ): void {
    if (details) {
      if (!event.data) {
        event.data = {};
      }
      const data = event.data;

      if (
        typeof details === "string" ||
        typeof details === "boolean" ||
        typeof details === "number"
      ) {
        data.Databag_info = details;
      } else {
        Object.entries(details).forEach(([key, detail]) => {
          const eventKey = `DataBag_${key}`;
          if (
            typeof detail === "string" ||
            typeof detail === "boolean" ||
            typeof detail === "number"
          ) {
            data[eventKey] = detail;
          } else if (key === "data") {
            Object.entries(detail).forEach(([key2, detail2]) => {
              const eventKey2 = `DataBag_${key2}`;
              data[eventKey2] = detail2 as string | number | boolean;
            });
          } else {
            data[eventKey] = JSON.stringify(detail);
          }
        });
      }
    }
  }
}
