import {
  IAuthenticationService,
  LoginState,
  IAccessToken,
  AuthStateChangedCallback,
  ISkypeTokenFetchResponse,
  Scope,
  ISkypeToken,
  IUserInfoFromToken,
  AuthServiceInitializedCallback,
  AuthzEndpointEnv,
  AuthzEndpoints,
  RedirectLoginErrorCallback,
  IBadgerFetchResponse,
} from "./authenticationService.interface";
import {
  PublicClientApplication,
  InteractionRequiredAuthError,
  AuthenticationResult,
  AuthError,
  RedirectRequest,
  PopupRequest,
} from "@azure/msal-browser-1p";
import {
  SOCIAL_MSAL_CONFIG,
  WORK_MSAL_CONFIG,
  VISITOR_SKYPE_TOKEN_TENANT,
  SCOPES_BY_LOGIN_TYPE,
  SOCIAL_ACCOUNT_ID_TOKEN_ENVIRONMENT,
  WORK_ACCOUNT_ID_TOKEN_ENVIRONMENT,
  WORK_ACCOUNT_ID_TOKEN_ENVIRONMENT_GOV,
  DELETE_MSAL_CONFIG,
  PROD_AUTHZ_ENDPOINTS,
  GCC_AUTHZ_ENDPOINTS,
  GCCH_AUTHZ_ENDPOINTS,
  DOD_AUTHZ_ENDPOINTS,
  AUTHZ_ENDPOINT_ENV,
  isGCCHOrDodCloud,
  MSAL_REDIRECT_URI_FOR_REDIRECT_LOGIN,
} from "../../configs/authConfigs";
import { AccountInfo } from "@azure/msal-common";
import {
  ILogger,
  IScenarioLogger,
  IScenarioLoggerJsonObject,
  LoggerLevels,
} from "../../common/logger/interface";
import { AxiosError } from "axios";
import { Logger, Scenario } from "../../common/logger/Logger";
import { ICache } from "./cache/cache.interface";
import { LocalStorageTokenCache } from "./cache/localStorageTokenCache";
import { cacheKeys, msalErrorCodes } from "../../common/constants";
import {
  areAccountsEqual,
  isValidBadgerTokenResponse,
  isValidSkypeTokenResponse,
} from "./auth-util";
import { v4 } from "uuid";
import {
  PortalAxiosError,
  PortalAxiosErrorType,
} from "../api/PortalAxiosError";
import {
  getErrorResponse,
  IError,
  isErrorResponseLicenseError,
  isErrorResponseSilentAuthInteractionRequiredError,
  isErrorResponseSkypeTokenMethodNotAllowedError,
} from "../slices/error";
import { deploymentConfig } from "../../configs/deploymentConfigs";
import { AXHelper } from "../api/axHelper";
import { SessionStorage } from "../../common/sessionStorage/SessionStorage";
import { buildLoggerScenarioKey } from "../../common/logger/utils";
import { ScenarioLogger } from "../../common/logger/ScenarioLogger";
import { RootState } from "../store/store";
import { Store } from "redux";
import { enableRedirectAcquireTokenSelector } from "../slices/ecsSlice";

export class AuthenticationService implements IAuthenticationService {
  private static _instance: AuthenticationService | null;

  private store?: Store<RootState>;

  private authStateChangedCallbacks: AuthStateChangedCallback[] = [];
  private authServiceIntializedCallbacks: AuthServiceInitializedCallback[] = [];
  private redirectLoginErrorCallbacks: RedirectLoginErrorCallback[] = [];
  private loginState: LoginState = LoginState.NotLoggedIn;
  private isInitialized: boolean = false;
  private logger: ILogger;
  private socialMsal: PublicClientApplication;
  private workMsal: PublicClientApplication;
  private deleteMsal: PublicClientApplication;
  private skypeTokenCache: ICache<ISkypeTokenFetchResponse>;
  private badgerTokenCache: ICache<IBadgerFetchResponse>;
  private accessTokenPromises: Map<string, Promise<IAccessToken>>;
  private skypeTokenPromises: Map<string, Promise<ISkypeToken>>;
  private userTenantId: string;

  private constructor() {
    this.logger = Logger.getInstance();
    this.socialMsal = new PublicClientApplication(SOCIAL_MSAL_CONFIG);
    this.workMsal = new PublicClientApplication(WORK_MSAL_CONFIG);
    this.deleteMsal = new PublicClientApplication(DELETE_MSAL_CONFIG);

    this.skypeTokenCache =
      new LocalStorageTokenCache<ISkypeTokenFetchResponse>();
    this.badgerTokenCache = new LocalStorageTokenCache<IBadgerFetchResponse>();
    this.accessTokenPromises = new Map();
    this.skypeTokenPromises = new Map();

    this.userTenantId = VISITOR_SKYPE_TOKEN_TENANT;

    this.init();
  }

  private async init(): Promise<void> {
    await Promise.allSettled([
      this.socialMsal.initialize(),
      this.workMsal.initialize(),
      this.deleteMsal.initialize(),
    ]);

    /**
     * handleRedirectPromise is for private events (can only be viewed if the user is in the correct tenant), in which
     * we redirect signed out users to the MSAL login page, and upon signing in and redirected back to our page, this
     * promise will run and resolve with a result after the auth tokens has been set to storage.
     * The handleRedirectPromise otherwise will resolve with a null immediately.
     */
    this.logger.createScenario(Scenario.HandleSocialMsalRedirectPromise);
    this.logger.createScenario(Scenario.HandleWorkMsalRedirectPromise);

    await Promise.allSettled([
      this.socialMsal
        .handleRedirectPromise()
        .then(this.handleSocialMsalRedirectPromise.bind(this))
        .catch(this.handleSocialMsalRedirectPromiseError.bind(this)),
      this.workMsal
        .handleRedirectPromise()
        .then(this.handleWorkMsalRedirectPromise.bind(this))
        .catch(this.handleWorkMsalRedirectPromiseError.bind(this)),
    ]);

    // Check local storage for accounts.
    if (this.isLoggedIntoSocialAccount() || this.isLoggedIntoWorkAccount()) {
      this.loginState = this.isLoggedIntoSocialAccount()
        ? LoginState.Social
        : LoginState.Work;
      this.logger.logTrace(
        LoggerLevels.info,
        `[AuthenticationService] [ctor] Found logged-in ${this.loginState} account`
      );
      this.setAuthenticationState(this.loginState);
    }

    this.isInitialized = true;
    this.setAuthServiceInitializedState(this.isInitialized);
  }

  private handleSocialMsalRedirectPromise(result: AuthenticationResult | null) {
    return this.handleMsalRedirectPromise(result, LoginState.Social);
  }
  private handleWorkMsalRedirectPromise(result: AuthenticationResult | null) {
    return this.handleMsalRedirectPromise(result, LoginState.Work);
  }
  private async handleMsalRedirectPromise(
    result: AuthenticationResult | null,
    loginType: LoginState.Work | LoginState.Social
  ) {
    this.logger
      .findScenario(
        loginType === LoginState.Social
          ? Scenario.HandleSocialMsalRedirectPromise
          : Scenario.HandleWorkMsalRedirectPromise
      )
      ?.stop({
        data: {
          isRedirect: (!!result).toString(),
        },
      });

    if (!result) {
      return;
    }

    await this.clearOtherAccounts(result.account);

    const scenario = this.popLoginLoggerScenarioFromSessionStorage();
    scenario?.stop();
    this.logger.logTrace(
      LoggerLevels.info,
      "[AuthenticationService] [handlseMsalRedirectPromise] finished redirect authentication successfully"
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private handleSocialMsalRedirectPromiseError(err: any) {
    return this.handleMsalRedirectPromiseError(err, LoginState.Social);
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private handleWorkMsalRedirectPromiseError(err: any) {
    return this.handleMsalRedirectPromiseError(err, LoginState.Work);
  }
  private handleMsalRedirectPromiseError(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    err: any,
    loginType: LoginState.Work | LoginState.Social
  ) {
    this.logger
      .findScenario(
        loginType === LoginState.Social
          ? Scenario.HandleSocialMsalRedirectPromise
          : Scenario.HandleWorkMsalRedirectPromise
      )
      ?.fail();

    const scenario = this.popLoginLoggerScenarioFromSessionStorage();
    const message = `error logging in via redirect: ${err}`;
    scenario?.fail({ message });
    this.logger.logTrace(
      LoggerLevels.error,
      `[AuthenticationService] [handleMsalRedirectPromiseError] ${message}`
    );

    this.setRedirectLoginErrorState(err);
  }

  public setStore(store: Store<RootState>): void {
    this.store = store;
  }

  private handlePopupError(
    method: string,
    scenario: IScenarioLogger | null,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    err: any
  ): Promise<never> {
    const message = `error attempting to open popup window: ${err}`;
    this.logger.logTrace(
      LoggerLevels.error,
      `[AuthenticationService] [${method}] ${message}`
    );
    scenario?.fail({ message });
    return Promise.reject(err);
  }

  private handleSkypeTokenFetchResponse(
    response: ISkypeTokenFetchResponse,
    shouldCacheResponse: boolean,
    scenario: IScenarioLogger | null,
    authzEndpointEnv: AuthzEndpointEnv
  ): Promise<ISkypeToken> {
    const accessToken = response.tokens.skypeToken;
    if (!accessToken || accessToken === "") {
      const message = `Access token returned is invalid. response.tokens.skypeToken=${accessToken}`;
      this.logger.logTrace(
        LoggerLevels.error,
        `[AuthenticationService] [handleSkypeTokenFetchResponse] ${message}`
      );
      scenario?.fail({
        message,
        data: { cacheHit: `${!shouldCacheResponse}` },
      });
      return Promise.reject(message);
    }

    let amsRegionUrl = undefined;
    let virtualEventsServiceUrl = undefined;
    if (
      authzEndpointEnv === AuthzEndpointEnv.Prod &&
      response.regionGtms &&
      process.env.REACT_APP_REGIONALIZE === "true" &&
      this.loginState === LoginState.Work
    ) {
      amsRegionUrl = response.regionGtms.amsV2;
      virtualEventsServiceUrl = response.regionGtms.virtualEventsService;
    }

    if (shouldCacheResponse) {
      const now = Date.now();
      const expiresInSeconds = response.tokens.expiresIn;
      const expiresInMilliseconds = expiresInSeconds * 1000;
      const expirationInEpochMilliseconds = now + expiresInMilliseconds;
      const cacheKey = this.getSkypeTokenCacheKey(
        authzEndpointEnv,
        this.loginState !== LoginState.Work
      );
      const cacheWriteSucceeded = this.skypeTokenCache.put(
        cacheKey,
        {
          tokens: { ...response.tokens },
          tenantId: response.tenantId,
          region: response.region,
          regionGtms: {
            amsV2: amsRegionUrl,
            virtualEventsService: virtualEventsServiceUrl,
          },
        },
        expirationInEpochMilliseconds
      );
      if (!cacheWriteSucceeded) {
        this.logger.logTrace(
          LoggerLevels.warn,
          "[AuthenticationService] [handleVisitorSkypeTokenResponse] cache write failed"
        );
      }
      scenario?.mark("cacheWriteFail");
    }
    const skypeTokenResponse: ISkypeToken = {
      accessToken: response.tokens.skypeToken,
      region: response.region,
      regionGtms: {
        amsV2: amsRegionUrl,
        virtualEventsService: virtualEventsServiceUrl,
      },
    };
    this.logger.logTrace(
      LoggerLevels.info,
      `[AuthenticationService] [handleSkypeTokenFetchResponse] regionalize ${process.env.REACT_APP_REGIONALIZE} amsRegionUrl ${amsRegionUrl}, virtualEventsServiceUrl ${virtualEventsServiceUrl}`
    );
    scenario?.stop({ data: { cacheHit: `${!shouldCacheResponse}` } });
    return Promise.resolve(skypeTokenResponse);
  }

  private getScopeByLoginState(scope: Scope): string {
    if (this.loginState === LoginState.Work) {
      return SCOPES_BY_LOGIN_TYPE.work[scope];
    } else {
      return SCOPES_BY_LOGIN_TYPE.social[scope];
    }
  }

  private isLoggedIntoSocialAccount(): boolean {
    return (
      this.socialMsal.getAllAccounts().length > 0 &&
      this.socialMsal.getAllAccounts()[0].environment ===
        SOCIAL_ACCOUNT_ID_TOKEN_ENVIRONMENT
    );
  }

  private isLoggedIntoWorkAccount(): boolean {
    this.workMsal.getAllAccounts().forEach((element) => {
      this.logger.logTrace(
        LoggerLevels.info,
        `Found Account: ${element.environment}`
      );
    });
    return (
      this.workMsal.getAllAccounts().length > 0 &&
      ((!isGCCHOrDodCloud() &&
        this.workMsal.getAllAccounts()[0].environment ===
          WORK_ACCOUNT_ID_TOKEN_ENVIRONMENT) ||
        (isGCCHOrDodCloud() &&
          this.workMsal.getAllAccounts()[0].environment ===
            WORK_ACCOUNT_ID_TOKEN_ENVIRONMENT_GOV))
    );
  }

  /**
   * Calls authStateChangedCallbacks which update redux store state and AXHelper state.
   */
  private setAuthenticationState(loginState: LoginState): void {
    this.loginState = loginState;
    this.authStateChangedCallbacks.forEach((cb) => cb(loginState));
  }

  /**
   * Calls authServiceIntializedCallbacks which update redux store state.
   */
  private setAuthServiceInitializedState(value: boolean): void {
    this.authServiceIntializedCallbacks.forEach((cb) => cb(value));
  }

  /**
   * Calls redirectLoginErrorCallbacks which update redux store state.
   */
  private setRedirectLoginErrorState(error: unknown): void {
    this.redirectLoginErrorCallbacks.forEach((cb) => cb(error));
  }

  public static getInstance(): AuthenticationService {
    return this._instance || (this._instance = new this());
  }

  public getAccount(): AccountInfo | null {
    if (this.loginState === LoginState.NotLoggedIn) {
      return null;
    }
    if (this.loginState === LoginState.Social) {
      return this.socialMsal.getAllAccounts()[0];
    } else {
      return this.workMsal.getAllAccounts()[0];
    }
  }

  /**
   * On transient MSAL errors this method will retry acquiring access token silently,
   * then it will retry acquiring access token with popup.
   */
  private acquireAccessTokenSilent(
    account: AccountInfo,
    scopes: string[]
  ): Promise<IAccessToken> {
    const scopesKey = scopes.join(" ");

    // Check if there's already an in-flight request for a token for the same scope. If so, just return that one.
    // This avoids making a bunch of calls to MSAL's API.
    if (this.accessTokenPromises.has(scopesKey)) {
      // Non-falsy check is redundant because of .has() check but it shuts the compiler up
      const tokenPromise = this.accessTokenPromises.get(scopesKey);
      if (!!tokenPromise) {
        return tokenPromise;
      }
    }

    const promise = this.acquireAccessTokenSilentAsync(account, scopes);
    this.accessTokenPromises.set(scopesKey, promise);
    promise
      .then(() => {
        this.accessTokenPromises.delete(scopesKey);
      })
      .catch(() => {
        this.accessTokenPromises.delete(scopesKey);
      });
    return promise;
  }

  private async acquireAccessTokenSilentAsync(
    account: AccountInfo,
    scopes: string[],
    isRetryingSilent: boolean = false,
    isRetryingPopup: boolean = false
  ): Promise<IAccessToken> {
    const accessTokenFetchScenario = this.logger.createScenario(
      Scenario.AccessTokenFetch,
      {
        data: { scopes: scopes.join(" ") },
      }
    );

    //we know that account exists at this point but have to do this check otherwise the compiler will complain
    this.userTenantId = account ? account?.tenantId : "";

    const msal =
      this.loginState === LoginState.Social ? this.socialMsal : this.workMsal;

    // Let's include all the scopes necessary for the our App for acquire token with interaction.
    const extraScopesToConsent =
      this.loginState === LoginState.Social
        ? []
        : [
            SCOPES_BY_LOGIN_TYPE.work[Scope.CMD_SERVICES],
            SCOPES_BY_LOGIN_TYPE.work[Scope.SKYPE_SPACES],
          ];

    try {
      let authResult: AuthenticationResult;
      if (!isRetryingPopup) {
        authResult = await msal.acquireTokenSilent({
          account,
          scopes,
        });
      } else {
        authResult = await msal.acquireTokenPopup({
          account,
          scopes,
          extraScopesToConsent,
        });

        // User may select different account, clear the one that they were logged into previously.
        await this.clearOtherAccounts(authResult.account);
      }

      const accessToken = authResult.accessToken;
      if (!accessToken || accessToken === "") {
        const message = `Access token returned is invalid. response.accessToken=${accessToken}`;
        this.logger.logTrace(
          LoggerLevels.error,
          `[AuthenticationService] [acquireAccessTokenSilentAsync] ${message}`
        );
        accessTokenFetchScenario?.fail({ message });
        throw new Error(message);
      }

      const tokenResponse: IAccessToken = {
        accessToken,
      };
      accessTokenFetchScenario?.stop();
      return tokenResponse;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      const fetchMethod = isRetryingPopup ? "popup" : "silent";
      if (err instanceof InteractionRequiredAuthError) {
        const message = `Interaction required error while acquiring access token ${fetchMethod}: ${err}`;
        const msalCorrelationId =
          err instanceof AuthError ? err.correlationId : "";
        accessTokenFetchScenario?.stop({
          message,
          data: { msalCorrelationId },
        });
        this.logger.logTrace(
          LoggerLevels.warn,
          `[AuthenticationService] [acquireAccessTokenSilentAsync] got interaction required error while fetching token ${fetchMethod}: ${err?.errorMessage}`
        );

        if (this.enableRedirectAcquireToken) {
          // Let error be thrown and handled by whatever is calling this function (Eg. Stopping logger scenarios)
          // while waiting for redirect to happen asychronously.
          this.acquireTokenRedirect(msal, {
            account,
            scopes,
            extraScopesToConsent,
          });
        } else if (!isRetryingPopup) {
          return this.acquireAccessTokenSilentAsync(
            account,
            scopes,
            false,
            true
          );
        }
      } else if (
        err instanceof AuthError &&
        /**
         * MSAL team recommends retrying with popup for invalid_grant error.
         * This may give user useful info on the error via the popup.
         */
        err.errorCode === "invalid_grant"
      ) {
        const message = `Invalid grant error while acquiring access token ${fetchMethod}: ${err}`;
        const msalCorrelationId =
          err instanceof AuthError ? err.correlationId : "";
        accessTokenFetchScenario?.stop({
          message,
          data: { msalCorrelationId },
        });
        this.logger.logTrace(
          LoggerLevels.warn,
          `[AuthenticationService] [acquireAccessTokenSilentAsync] got invalid grant error while fetching token ${fetchMethod}: ${err?.errorMessage}`
        );
        if (this.enableRedirectAcquireToken) {
          // Let error be thrown and handled by whatever is calling this function (Eg. Stopping logger scenarios)
          // while waiting for redirect to happen asychronously.
          this.acquireTokenRedirect(msal, {
            account,
            scopes,
            extraScopesToConsent,
          });
        } else if (!isRetryingPopup) {
          return this.acquireAccessTokenSilentAsync(
            account,
            scopes,
            false,
            true
          );
        }
      } else if (
        err instanceof AuthError &&
        err.errorCode === "user_cancelled"
      ) {
        const message = `User cancelled error while acquiring access token ${fetchMethod}: ${err}`;
        const msalCorrelationId =
          err instanceof AuthError ? err.correlationId : "";
        accessTokenFetchScenario?.stop({
          message,
          data: { msalCorrelationId },
        });
        this.logger.logTrace(
          LoggerLevels.warn,
          `[AuthenticationService] [acquireAccessTokenSilentAsync] got user cancelled error while fetching token ${fetchMethod}: ${err?.errorMessage}`
        );
      } else if (
        err instanceof AuthError &&
        err.errorCode === "invalid_resource"
      ) {
        const message = `Invalid resource error while acquiring access token ${fetchMethod}: ${err}`;
        const msalCorrelationId =
          err instanceof AuthError ? err.correlationId : "";
        accessTokenFetchScenario?.stop({
          message,
          data: { msalCorrelationId },
        });
        this.logger.logTrace(
          LoggerLevels.warn,
          `[AuthenticationService] [acquireAccessTokenSilentAsync] invalid resource error while fetching token ${fetchMethod}: ${err?.errorMessage}`
        );
      } else if (
        err instanceof AuthError &&
        /**
         * These errors are transient.
         * On transient MSAL errors we want to retry acquiring access token silent,
         * then retry acquiring access token popup/redirect.
         */
        (err.errorCode === "monitor_window_timeout" ||
          err.errorCode === "post_request_failed" ||
          err.errorCode === "no_network_connectivity")
      ) {
        let message;
        switch (err.errorCode) {
          case "monitor_window_timeout":
            message = `Monitor window timeout error while acquiring access token ${fetchMethod}: ${err}`;
            break;
          case "post_request_failed":
            message = `Post request failed error while acquiring access token ${fetchMethod}: ${err}`;
            break;
          case "no_network_connectivity":
            message = `No network connectivity error while acquiring access token ${fetchMethod}: ${err}`;
            break;
        }
        const msalCorrelationId =
          err instanceof AuthError ? err.correlationId : "";

        this.logger.logTrace(
          LoggerLevels.warn,
          `[AuthenticationService] [acquireAccessTokenSilentAsync] got error code "${err.errorCode}" while fetching token ${fetchMethod}: ${err?.errorMessage}`
        );

        if (!isRetryingSilent && !isRetryingPopup) {
          accessTokenFetchScenario?.stop({
            message,
            data: { msalCorrelationId },
          });
          return this.acquireAccessTokenSilentAsync(
            account,
            scopes,
            true,
            false
          );
        } else if (this.enableRedirectAcquireToken) {
          accessTokenFetchScenario?.stop({
            message,
            data: { msalCorrelationId },
          });
          // Let error be thrown and handled by whatever is calling this function (Eg. Stopping logger scenarios)
          // while waiting for redirect to happen asychronously.
          this.acquireTokenRedirect(msal, {
            account,
            scopes,
            extraScopesToConsent,
          });
        } else if (!isRetryingPopup) {
          accessTokenFetchScenario?.stop({
            message,
            data: { msalCorrelationId },
          });
          return this.acquireAccessTokenSilentAsync(
            account,
            scopes,
            false,
            true
          );
        } else {
          accessTokenFetchScenario?.fail({
            message,
            data: { msalCorrelationId },
          });
        }
      } else {
        const message = `Error acquiring access token ${fetchMethod}: ${err}`;
        const msalCorrelationId =
          err instanceof AuthError ? err.correlationId : "";
        this.logger.logTrace(
          LoggerLevels.error,
          `[AuthenticationService] [acquireAccessTokenSilentAsync] error acquiring token ${fetchMethod} for scopes ${scopes}: ${err}`
        );
        accessTokenFetchScenario?.fail({
          message,
          data: { msalCorrelationId },
        });
      }

      throw err;
    }
  }

  private acquireTokenRedirect(
    msal: PublicClientApplication,
    request: RedirectRequest
  ): Promise<void> {
    this.setRedirectLoginErrorState(undefined);

    /**
     * Note: {@link PublicClientApplication.acquireTokenRedirect} and {@link PublicClientApplication.loginRedirect}
     * uses the same {@link PublicClientApplication.handleRedirectPromise} upon redirecting back to the app,
     * so it's hard to distinguish between the two after redirecting back to the app, let's flow what {@link this.login} does.
     */
    const loginType =
      msal === this.socialMsal ? LoginState.Social : LoginState.Work;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const scenario = this.logger.createScenario(Scenario.Login, {
      data: { loginType },
    })!;
    scenario.mark("authenticationService.acquireTokenRedirect");

    const loggerScenarioKey = buildLoggerScenarioKey(scenario.name);
    SessionStorage.put(loggerScenarioKey, scenario);
    const res = msal.acquireTokenRedirect({
      ...request,
      redirectUri: MSAL_REDIRECT_URI_FOR_REDIRECT_LOGIN,
    });

    res.catch((err) => {
      SessionStorage.delete(loggerScenarioKey);

      const message = `error initiating acquire token tedirect: ${err}`;
      scenario?.fail({ message });
      this.logger.logTrace(
        LoggerLevels.error,
        `[AuthenticationService] [acquireTokenRedirect] ${message}`
      );
      this.setRedirectLoginErrorState(err);
    });

    return res;
  }

  public async acquireToken(
    account: AccountInfo,
    scope: string
  ): Promise<IAccessToken> {
    return this.acquireAccessTokenSilent(account, [scope]);
  }

  public getAccessTokenForUser(
    scope: Scope,
    scenarioLogger?: IScenarioLogger
  ): Promise<IAccessToken> {
    scenarioLogger?.mark(
      "authenticationService.getAccessTokenForUser",
      undefined
    );
    const account = this.getAccount();
    if (!account) {
      const message =
        "User not logged in. Cannot acquire access token when user is not authenticated.";
      this.logger.logTrace(
        LoggerLevels.warn,
        `[AuthenticationService] [getAccessTokenForUser] ${message}`
      );
      scenarioLogger?.mark(
        "authenticationService.getAccessTokenForUser - userNotLoggedIn",
        undefined,
        { message }
      );
      return Promise.reject(message);
    }

    scenarioLogger?.mark(
      "authenticationService.acquireAccessTokenSilent",
      undefined
    );
    const promise = this.acquireAccessTokenSilent(account, [
      this.getScopeByLoginState(scope),
    ]);
    promise
      .then(() => {
        scenarioLogger?.mark(
          "authenticationService.acquireAccessTokenSilent - success",
          undefined
        );
      })
      .catch(() => {
        scenarioLogger?.mark(
          "authenticationService.acquireAccessTokenSilent - failed",
          undefined
        );
      });

    return promise;
  }

  public getLoginState(): LoginState {
    return this.loginState;
  }

  /**
   * Get badger token for anonymous playback
   *
   * https://onedrive.visualstudio.com/SharePoint%20Online/_git/COMPTeamWiki?path=/SharePoint%20Collab%20(Sharing)/Team%20Docs/Microservices/Badger/Overview.md&_a=preview&anchor=request
   */
  public async getBadgerToken(): Promise<IBadgerFetchResponse> {
    const cachedTokenKey = cacheKeys.badgerToken;
    const cachedToken = this.badgerTokenCache.get(cachedTokenKey);

    if (cachedToken && isValidBadgerTokenResponse(cachedToken)) {
      return cachedToken;
    }
    const scenario = this.logger.createScenario(Scenario.BadgerTokenFetch);
    const url = "https://api.badgerp.svc.ms/v1.0/Token";
    const data = {
      appId: WORK_MSAL_CONFIG.auth.clientId,
    };
    const correlationId = v4();
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await AXHelper.post<IBadgerFetchResponse>(
      "AuthenticationService.getBadgerToken",
      url,
      data,
      config,
      correlationId,
      scenario || undefined
    ).catch((err) => {
      const errResponse: IError = getErrorResponse("badgerTokenFetch", err);
      this.logger.logTrace(
        LoggerLevels.error,
        `Badger Token Fetch got error: ${
          errResponse.responseErrorMessage || errResponse.errorMessage
        }`
      );
      throw err;
    });
    const cacheWriteSucceeded = this.badgerTokenCache.put(
      cachedTokenKey,
      response.data
    );
    if (!cacheWriteSucceeded) {
      this.logger.logTrace(
        LoggerLevels.warn,
        "[AuthenticationService] [getBadgerToken] cache write failed"
      );
    }
    return response.data;
  }

  public getSkypeToken(eventTenantId: string): Promise<ISkypeToken> {
    const authzEndpointEnv = AUTHZ_ENDPOINT_ENV;
    const promise = this.skypeTokenPromises.get(authzEndpointEnv);
    if (promise) {
      return promise;
    }
    switch (this.loginState) {
      case LoginState.NotLoggedIn:
      case LoginState.Social:
        return this.getVisitorSkypeToken(authzEndpointEnv, eventTenantId);
      case LoginState.Work:
        return this.getAuthenticatedSkypeToken(authzEndpointEnv);
    }
  }

  public getSkypeTokenForAms(eventTenantId: string): Promise<ISkypeToken> {
    const promise = this.skypeTokenPromises.get(AUTHZ_ENDPOINT_ENV);
    if (promise) {
      return promise;
    }
    switch (this.loginState) {
      case LoginState.NotLoggedIn:
      case LoginState.Social:
        return this.getVisitorSkypeToken(AUTHZ_ENDPOINT_ENV, eventTenantId);
      case LoginState.Work:
        return this.getAuthenticatedSkypeToken(AUTHZ_ENDPOINT_ENV);
    }
  }

  public async getSkypeTokenForBrb(): Promise<ISkypeToken> {
    try {
      this.logger.logTrace(
        LoggerLevels.info,
        "BRB authorization get prod skype token."
      );
      // Try skype token with out event tenant ID incase is a invalid or non existant tenant.
      return await this.getSkypeToken("");
    } catch (err) {
      const errResponse: IError = getErrorResponse(
        "brbClientAuthorization",
        err
      );

      if (
        isErrorResponseLicenseError(errResponse) ||
        isErrorResponseSilentAuthInteractionRequiredError(errResponse)
      ) {
        // If user licnese error or interaction required, try visitor skype token.
        // Try visitor skype token with out event tenant ID incase is a invalid or non existant tenant.
        this.logger.logTrace(
          LoggerLevels.info,
          "BRB authorization get visitor skype token."
        );
        return await this.getVisitorSkypeToken(AUTHZ_ENDPOINT_ENV, "");
      }

      this.logger.logTrace(
        LoggerLevels.error,
        `BRB authorization get prod skype token error: ${
          errResponse.responseErrorMessage || errResponse.errorMessage
        }`
      );
      throw err;
    }
  }

  private getAuthenticatedSkypeToken(
    authzEndpointEnv: AuthzEndpointEnv
  ): Promise<ISkypeToken> {
    const scenario = this.logger.createScenario(Scenario.AuthSkypeTokenFetch);
    const cachedTokenKey = this.getSkypeTokenCacheKey(authzEndpointEnv, false);
    const cachedToken = this.skypeTokenCache.get(cachedTokenKey);
    if (
      cachedToken &&
      isValidSkypeTokenResponse(cachedToken) &&
      cachedToken.tenantId === this.userTenantId
    ) {
      return this.handleSkypeTokenFetchResponse(
        cachedToken,
        false,
        scenario,
        authzEndpointEnv
      );
    }
    const promise = this.getAccessTokenForUser(
      Scope.SKYPE_SPACES,
      scenario || undefined
    )
      .then((accessTokenResponse) => {
        const url = this.getAuthzEndpoint(authzEndpointEnv, false);
        const data = {};
        const accessToken = accessTokenResponse.accessToken;
        const correlationId = v4();
        const config = {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "x-ms-request-id": correlationId,
            "x-ms-client-type": "virtual-events-portal",
            "x-ms-client-version": "2021/WebPortal-1.0",
          },
        };
        return AXHelper.post<ISkypeTokenFetchResponse>(
          "AuthenticationService.getAuthenticatedSkypeToken",
          url,
          data,
          config,
          correlationId,
          scenario || undefined
        )
          .then((response) => {
            //This needs to be set here so the response data used in handleSkypeTokenFetchResponse will have information about
            //userTenantId which needs to be cached so if the same user logs in again we just rely on the cache instead of
            //making another network call
            response.data.tenantId = this.userTenantId;

            this.logger.logTrace(
              LoggerLevels.info,
              `[AuthenticationService] [getAuthenticatedSkypeToken] ${response.data.tenantId}`
            );

            const authzCorrelationId =
              response?.headers?.["correlation-id"] || "";
            const authzXMsedgeRef = response?.headers?.["x-msedge-ref"] || "";
            scenario?.mark(
              "authenticationService.authSkypeTokenFetch - success",
              undefined,
              {
                data: { authzCorrelationId, authzXMsedgeRef },
              }
            );

            const skypeTokenPromise = this.handleSkypeTokenFetchResponse(
              response.data,
              true,
              scenario,
              authzEndpointEnv
            );

            this.skypeTokenPromises.delete(authzEndpointEnv);

            return skypeTokenPromise;
          })
          .catch((err) => {
            const skypeTokenPromise = this.handleSkypeTokenFetchError(
              "authenticated",
              err,
              scenario
            );

            this.skypeTokenPromises.delete(authzEndpointEnv);

            return skypeTokenPromise;
          });
      })
      .catch((err) => {
        this.skypeTokenPromises.delete(authzEndpointEnv);
        const message = `Error fetching skype spaces access token: ${err}`;
        this.logger?.logTrace(
          LoggerLevels.error,
          `[AuthenticationService] [getAuthenticatedSkypeToken] ${message}`
        );
        return Promise.reject(err);
      });
    this.skypeTokenPromises.set(authzEndpointEnv, promise);
    return promise;
  }

  /**
   * Note: We are interested in the vistor skype token belonging to the tenant id,
   * as that grants access to the proper resource across regions using the global region url.
   */
  private getVisitorSkypeToken(
    authzEndpointEnv: AuthzEndpointEnv,
    eventTenantId: string
  ): Promise<ISkypeToken> {
    const scenario = this.logger.createScenario(
      Scenario.VisitorSkypeTokenFetch
    );
    const cachedTokenKey = this.getSkypeTokenCacheKey(authzEndpointEnv, true);
    const cachedToken = this.skypeTokenCache.get(cachedTokenKey);
    if (
      cachedToken &&
      isValidSkypeTokenResponse(cachedToken) &&
      cachedToken.tenantId === eventTenantId
    ) {
      return this.handleSkypeTokenFetchResponse(
        cachedToken,
        false,
        scenario,
        authzEndpointEnv
      );
    } else {
      const url = this.getAuthzEndpoint(authzEndpointEnv, true);
      const data = {
        tenantId: eventTenantId ? eventTenantId : VISITOR_SKYPE_TOKEN_TENANT,
      };
      const correlationId = v4();
      const config = {
        headers: {
          "x-ms-client-type": "virtual-events-portal",
          "x-ms-request-id": correlationId,
        },
      };
      const promise = AXHelper.post<ISkypeTokenFetchResponse>(
        "AuthenticationService.getVisitorSkypeToken",
        url,
        data,
        config,
        correlationId,
        scenario || undefined
      )
        .then((response) => {
          if (
            (typeof response.data === "string" ||
              response.data instanceof String) &&
            (deploymentConfig.deployment === "local" ||
              deploymentConfig.deployment === "Dev" ||
              deploymentConfig.deployment === "Int" ||
              deploymentConfig.deployment === "MSIT")
          ) {
            scenario?.mark("fetchResponseIsString", undefined, {
              message: `Fetch response data: ${response.data}`,
            });
          }

          //This needs to be set here so the response data used in handleSkypeTokenFetchResponse will have information about
          //eventTenantId which needs to be cached
          response.data.tenantId = eventTenantId;

          this.logger.logTrace(
            LoggerLevels.info,
            `[AuthenticationService] [getVisitorSkypeToken] ${
              eventTenantId ? eventTenantId : VISITOR_SKYPE_TOKEN_TENANT
            }`
          );

          const authzCorrelationId =
            response?.headers?.["correlation-id"] || "";
          const authzXMsedgeRef = response?.headers?.["x-msedge-ref"] || "";
          scenario?.mark(
            "authenticationService.visitorSkypeTokenFetch - success",
            undefined,
            {
              data: { authzCorrelationId, authzXMsedgeRef },
            }
          );

          const skypeTokenPromise = this.handleSkypeTokenFetchResponse(
            response.data,
            true,
            scenario,
            authzEndpointEnv
          );

          this.skypeTokenPromises.delete(authzEndpointEnv);

          return skypeTokenPromise;
        })
        .catch((err) => {
          const skypeTokenPromise = this.handleSkypeTokenFetchError(
            "visitor",
            err,
            scenario
          );

          this.skypeTokenPromises.delete(authzEndpointEnv);

          return skypeTokenPromise;
        });
      this.skypeTokenPromises.set(authzEndpointEnv, promise);
      return promise;
    }
  }

  private handleSkypeTokenFetchError(
    type: "authenticated" | "visitor",
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    err: any,
    scenario: IScenarioLogger | null
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Promise<any> {
    let message: string;
    // Following error handling example from axios docs: https://axios-http.com/docs/handling_errors
    if (err.response) {
      message = `Got error response from authz. status=${
        err.response.status
      }, data=${JSON.stringify(err.response.data)}`;
    } else if (err.request) {
      message = `No response recieved from authz. request=${err.request}`;
    } else {
      message = `error requesting ${type} skype token: ${err}`;
    }
    this.logger?.logTrace(
      LoggerLevels.error,
      `[AuthenticationService] [handleSkypeTokenFetchError] ${message}`
    );
    const authzCorrelationId = err?.response?.headers?.["correlation-id"] || "";
    const authzXMsedgeRef = err?.response?.headers?.["x-msedge-ref"] || "";

    err =
      err instanceof AxiosError
        ? PortalAxiosError.fromAxiosError(PortalAxiosErrorType.SKYPE_TOKEN, err)
        : err;

    const errResponse: IError = getErrorResponse(
      "handleSkypeTokenFetchError",
      err
    );
    if (isErrorResponseSkypeTokenMethodNotAllowedError(errResponse)) {
      scenario?.stop({
        message,
        data: { authzCorrelationId, authzXMsedgeRef },
      });
    } else {
      scenario?.fail({
        message,
        data: { authzCorrelationId, authzXMsedgeRef },
      });
    }

    return Promise.reject(err);
  }

  private getAuthzEndpoint(
    authzEndpointEnv: AuthzEndpointEnv,
    isVisitor: boolean
  ): string {
    let authzEndpoints: AuthzEndpoints;
    switch (authzEndpointEnv) {
      case AuthzEndpointEnv.Int:
      case AuthzEndpointEnv.Prod:
        authzEndpoints = PROD_AUTHZ_ENDPOINTS;
        break;
      case AuthzEndpointEnv.GCC:
        authzEndpoints = GCC_AUTHZ_ENDPOINTS;
        break;
      case AuthzEndpointEnv.GCCH:
        authzEndpoints = GCCH_AUTHZ_ENDPOINTS;
        break;
      case AuthzEndpointEnv.DOD:
        authzEndpoints = DOD_AUTHZ_ENDPOINTS;
        break;
    }

    return isVisitor
      ? authzEndpoints.VISITOR_SKYPE_TOKEN
      : authzEndpoints.AUTH_SKYPE_TOKEN;
  }

  private getSkypeTokenCacheKey(
    authzEndpointEnv: AuthzEndpointEnv,
    isVisitor: boolean
  ): string {
    if (isVisitor) {
      switch (authzEndpointEnv) {
        case AuthzEndpointEnv.Int:
          return cacheKeys.intVisitorSkypeToken;
        case AuthzEndpointEnv.Prod:
          return cacheKeys.visitorSkypeToken;
        case AuthzEndpointEnv.GCC:
          return cacheKeys.gccVisitorSkypeToken;
        case AuthzEndpointEnv.GCCH:
          return cacheKeys.gcchVisitorSkypeToken;
        case AuthzEndpointEnv.DOD:
          return cacheKeys.dodVisitorSkypeToken;
      }
    } else {
      switch (authzEndpointEnv) {
        case AuthzEndpointEnv.Int:
          return cacheKeys.intAuthSkypeToken;
        case AuthzEndpointEnv.Prod:
          return cacheKeys.authSkypeToken;
        case AuthzEndpointEnv.GCC:
          return cacheKeys.gccAuthSkypeToken;
        case AuthzEndpointEnv.GCCH:
          return cacheKeys.gcchAuthSkypeToken;
        case AuthzEndpointEnv.DOD:
          return cacheKeys.dodAuthSkypeToken;
      }
    }
  }

  public delete(loginPref: LoginState): Promise<void> {
    const loginType = loginPref === LoginState.Social ? "social" : "work";
    if (loginType === "work") {
      const msg = "Work user can not delete through this flow";
      return Promise.reject(msg);
    }
    const userDeleteScenario = this.logger.createScenario(Scenario.Login, {
      data: { loginType },
    });
    const msal = this.deleteMsal;
    return msal
      .loginPopup()
      .then(() => {
        this.setAuthenticationState(loginPref);
        userDeleteScenario?.stop();
        return Promise.resolve();
      })
      .catch((err) => {
        if (err.errorCode === msalErrorCodes.userCancelled) {
          const message = "User cancelled delete flow.";
          userDeleteScenario?.stop({ message });
          this.logger.logTrace(
            LoggerLevels.info,
            `[AuthenticationService] [Delete${loginPref}] ${message}`
          );
          return Promise.reject(message);
        }
        if (err.errorCode === msalErrorCodes.popupWindowError) {
          return this.handlePopupError(
            `Delete${loginPref}`,
            userDeleteScenario,
            err
          );
        }
        userDeleteScenario?.fail({ message: err });
        const message = `error deleting ${loginPref} account: ${err}`;
        this.logger.logTrace(
          LoggerLevels.error,
          `[AuthenticationService] [Delete${loginPref}] ${message}`
        );
        return Promise.reject(err);
      });
  }

  public login(
    loginPref: LoginState,
    redirect?: boolean,
    redirectStartPage?: string
  ): Promise<void> {
    /**
     * Remove any login errors when attempting to login again.
     * Note: Should be not nessecary for redirect login since the state will be gone upon redirect,
     * but if for some reason (Eg. ECS issue) this becomes popup login, we don't want the login
     * error to linger around and affect the auth error page if another error brings the user there.
     */
    this.setRedirectLoginErrorState(undefined);

    if (this.loginState !== LoginState.NotLoggedIn) {
      const loggedInto =
        this.loginState === LoginState.Social ? "social" : "work";
      return Promise.reject(
        `User already logged into their ${loggedInto} account`
      );
    }
    const loginType = loginPref === LoginState.Social ? "social" : "work";
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const scenario = this.logger.createScenario(Scenario.Login, {
      data: { loginType },
    })!;

    const msal =
      loginPref === LoginState.Social ? this.socialMsal : this.workMsal;
    const scopes =
      loginPref === LoginState.Social
        ? []
        : [SCOPES_BY_LOGIN_TYPE.work[Scope.CMD_SERVICES]];
    const extraScopesToConsent =
      loginPref === LoginState.Social
        ? []
        : [SCOPES_BY_LOGIN_TYPE.work[Scope.SKYPE_SPACES]];

    if (redirect) {
      /**
       * Redirect login will redirect away and back to the app, and the login {@link scenario} should continue though this.
       * Redirecting away and back will clear the active scenarios cache in {@link ILogger} from memory,
       * thus we need to cache it to {@link window.sessionStorage} before redirecting away,
       * and fetch it ({@link this.handleMsalRedirectPromise}, {@link this.handleMsalRedirectPromiseError}) after redirecting back to the app.
       */
      const loggerScenarioKey = buildLoggerScenarioKey(scenario.name);
      SessionStorage.put(loggerScenarioKey, scenario);

      const redirectRequest: RedirectRequest = {
        scopes,
        extraScopesToConsent,
        redirectUri: MSAL_REDIRECT_URI_FOR_REDIRECT_LOGIN,
        redirectStartPage,
        prompt: "select_account",
      };
      const res = msal.loginRedirect(redirectRequest);

      res.catch((err) => {
        SessionStorage.delete(loggerScenarioKey);
        const message = `error initiating login redirect: ${err}`;
        scenario?.fail({ message });
        this.logger.logTrace(
          LoggerLevels.error,
          `[AuthenticationService] [login] ${message}`
        );

        this.setRedirectLoginErrorState(err);
      });

      return res;
    }

    const popupRequest: PopupRequest = {
      scopes,
      extraScopesToConsent,
      prompt: "select_account",
    };

    return msal
      .loginPopup(popupRequest)
      .then(async (result) => {
        this.setAuthenticationState(loginPref);
        scenario?.stop();
        await this.clearOtherAccounts(result.account);
        return Promise.resolve();
      })
      .catch((err) => {
        if (err.errorCode === msalErrorCodes.userCancelled) {
          const message = "User cancelled login flow.";
          scenario?.stop({ message });
          this.logger.logTrace(
            LoggerLevels.info,
            `[AuthenticationService] [Login${loginPref}] ${message}`
          );
          return Promise.reject(err);
        }
        if (err.errorCode === msalErrorCodes.popupWindowError) {
          return this.handlePopupError(`Login${loginPref}`, scenario, err);
        }
        scenario?.fail({ message: err });
        const message = `error logging into ${loginPref} account: ${err}`;
        this.logger.logTrace(
          LoggerLevels.error,
          `[AuthenticationService] [Login${loginPref}] ${message}`
        );
        return Promise.reject(err);
      });
  }

  public logout(postLogoutRedirectUri?: string): Promise<void> {
    if (this.loginState === LoginState.NotLoggedIn) {
      return Promise.resolve();
    }
    const loginType = this.loginState === LoginState.Social ? "social" : "work";
    const scenario = this.logger.createScenario(Scenario.Logout, {
      data: { redirect: `${postLogoutRedirectUri}`, loginType },
    });
    const account = this.getAccount();
    const msal =
      this.loginState === LoginState.Social ? this.socialMsal : this.workMsal;
    return msal
      .logoutPopup({ account, postLogoutRedirectUri })
      .then(() => {
        this.setAuthenticationState(LoginState.NotLoggedIn);
        const cachedTokenKey = this.getSkypeTokenCacheKey(
          AUTHZ_ENDPOINT_ENV,
          false
        );
        this.skypeTokenCache.delete(cachedTokenKey);
        this.userTenantId = VISITOR_SKYPE_TOKEN_TENANT;
        scenario?.stop();
        return Promise.resolve();
      })
      .catch((err) => {
        this.logger.logTrace(
          LoggerLevels.error,
          `[AuthenticationService] [logout] logout error: ${err}`
        );
        scenario?.fail({ message: err });
        if (err.errorCode === msalErrorCodes.popupWindowError) {
          return this.handlePopupError("logout", scenario, err);
        }
        return Promise.reject(err);
      });
  }

  /**
   * This function logouts/clears all other accounts from our MSAL cache, accounts could be still have an active service session.
   * (Eg. Our app will think the accounts are logged out, but user might be able to login without entering credentials
   * since they might have an active service session)
   */
  private async clearOtherAccounts(currentAccount: AccountInfo): Promise<void> {
    const logoutPromises: Promise<void>[] = [];
    this.socialMsal.getAllAccounts().forEach((account) => {
      if (!areAccountsEqual(account, currentAccount)) {
        logoutPromises.push(
          this.socialMsal.logoutRedirect({
            account,
            onRedirectNavigate: () => false,
          })
        );
      }
    });
    this.workMsal.getAllAccounts().forEach((account) => {
      if (!areAccountsEqual(account, currentAccount)) {
        logoutPromises.push(
          this.workMsal.logoutRedirect({
            account,
            onRedirectNavigate: () => false,
          })
        );
      }
    });
    await Promise.allSettled(logoutPromises);
  }

  public registerAuthStateChangedCallback(cb: AuthStateChangedCallback): void {
    this.authStateChangedCallbacks.push(cb);
    if (this.userIsAuthenticated()) {
      // We may have found a session in cookies when the service was constructed.
      // If so, notify listeners that we're already logged in.
      cb(this.getLoginState());
    }
  }

  public registerAuthServiceIntializedCallback(
    cb: AuthServiceInitializedCallback
  ): void {
    this.authServiceIntializedCallbacks.push(cb);
    if (this.isInitialized) {
      cb(this.isInitialized);
    }
  }

  /**
   * Callback function to call when a redirect login error occurs.
   * Used to dispatch the action to set a state for the error in our redux store.
   */
  public registerRedirectLoginErrorCallback(
    cb: RedirectLoginErrorCallback
  ): void {
    this.redirectLoginErrorCallbacks.push(cb);
  }

  public userIsAuthenticated(): boolean {
    return this.loginState !== LoginState.NotLoggedIn;
  }

  public getUserInfoFromToken(): IUserInfoFromToken | undefined {
    const userInfo = this.getAccount()?.idTokenClaims;
    let oid = "";
    let tid = "";
    let userInfoFromToken: IUserInfoFromToken = {
      oid: "",
      tid: "",
    };
    if (userInfo) {
      const values = Object.entries(userInfo);
      values.forEach(([key, value]) => {
        const keyType = typeof key;
        if (keyType === "string" && key === "oid") {
          oid = value as string;
        } else if (keyType === "string" && key === "tid") {
          tid = value as string;
        }
      });
      userInfoFromToken = {
        oid: oid,
        tid: tid,
      };
    }
    return userInfo ? userInfoFromToken : undefined;
  }

  private popLoginLoggerScenarioFromSessionStorage(): IScenarioLogger | null {
    const scenarioSessionStorageKey = buildLoggerScenarioKey(Scenario.Login);
    const scenarioJsonObject = SessionStorage.get<IScenarioLoggerJsonObject>(
      scenarioSessionStorageKey
    );
    SessionStorage.delete(scenarioSessionStorageKey);

    if (!scenarioJsonObject) {
      return null;
    }

    const scenario = ScenarioLogger.fromJson(this.logger, scenarioJsonObject);
    this.logger.insertScenario(scenario);
    return scenario;
  }

  private get enableRedirectAcquireToken(): boolean {
    return this.store?.getState()
      ? enableRedirectAcquireTokenSelector(this.store?.getState())
      : false;
  }
}
